/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Slider from "react-slick"
import { useContext } from "react"
import { Container, Box, Hidden } from "@mui/material"
import Icon from "@mdi/react"
import {
	mdiCheckBold,
	mdiAlertDecagram,
	mdiImageMultiple,
	mdiAccountAlert,
	mdiKeyVariant,
	mdiKeyChange,
	mdiCertificate,
	mdiContentCopy,
	mdiShieldLock,
	mdiFileCertificate,
} from "@mdi/js"
import { createCloudinaryLegacyURL } from "@cloudinary/url-gen"

import Link from "../../components/i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import useTranslation from "hooks/useTranslation"

import { CountryCode } from "@secureo/common/typings/CountryCode"

import { makeStyles } from "makeStyles"
import VideoueberwachungBg from "../../svg/homePageBanner/BG-Videoueberwachung-slider.svg"
import EvvaAirkeyBg from "../../svg/homePageBanner/BG-AirKey-Startset-slider.svg"
import SafeHeroBg from "../../svg/homePageBanner/safehero-beneftis-bg.svg"
import RhmUdmBg from "../../svg/homePageBanner/rhm-udm-bg.svg"

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
	},
	// WelcomeBanner
	welcomeBannerH1: {
		fontSize: "36px",
		textTransform: "uppercase",
		margin: 0,
		[theme.breakpoints.down("md")]: {
			fontSize: "26px",
			lineHeight: "1",
			marginTop: "8px",
			marginBottom: "16px",
		},
	},
	hero: {
		position: "absolute",
		right: 0,
		maxHeight: "320px",
		zIndex: 0,
		[theme.breakpoints.up("md")]: {
			top: 0,
			maxHeight: "320px",
		},
		[theme.breakpoints.down("lg")]: {
			maxHeight: "320px",
			bottom: 0,
			opacity: 0.2,
		},
		[theme.breakpoints.down("sm")]: {
			maxHeight: "200px",
			bottom: 0,
			opacity: 0.3,
		},
	},
	object: {
		position: "absolute",
		right: 0,
		bottom: 0,
		maxHeight: "280px",
		zIndex: 0,
		[theme.breakpoints.down("sm")]: {
			maxHeight: "140px",
		},
	},
	welcomeBannerUl: {
		lineHeight: "110%",
		listStyle: "none",
		marginLeft: 0,
		paddingLeft: "1.7em",
		textIndent: "-1.7em",
	},
	welcomeBannerLi: {
		lineHeight: "1.5",
		marginTop: 0,
		[theme.breakpoints.up("sm")]: {
			marginTop: 5,
		},
	},
}))

const welcomeImg = createCloudinaryLegacyURL("Homepage/osccdgkrxshumqx0nmbu.png", {
	cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
	secure: true,
	transformation: [
		{ height: 300 },
		{ crop: "fill" },
		{ dpr: "auto" },
		{ fetchFormat: "auto" },
		{ quality: "auto" },
	],
})

const Welcome = ({ language }: { language: string }) => {
	const { classes } = useStyles()

	const { t } = useTranslation(["common", "home"])

	return (
		<div>
			<SafeHeroBg
				style={{
					width: "100%",
					position: "absolute",
					top: 0,
				}}
			/>
			<Container style={{ position: "relative", height: "320px" }}>
				<Box py={5}>
					<div style={{ position: "relative", zIndex: 1, color: "black" }}>
						<div>
							<div style={{ fontWeight: "bold" }}>SafeHero.com</div>
							<h1 className={classes.welcomeBannerH1} style={{ marginBottom: 0 }}>
								{t("common:slogan")}
							</h1>
						</div>
						<ul className={classes.welcomeBannerUl}>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiCheckBold} size="1rem" color="#7abfac" />
								</Box>
								<span>{t("home:expertAdvice")}</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1} style={{ marginTop: "5px" }}>
									<Icon path={mdiCheckBold} size="1rem" color="#7abfac" />
								</Box>
								<span>{t("home:pricePerformanceRatio")}</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1} style={{ marginTop: "5px" }}>
									<Icon path={mdiCheckBold} size="1rem" color="#7abfac" />
								</Box>
								<span>{t("home:safeOnlinePayment")}</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1} style={{ marginTop: "5px" }}>
									<Icon path={mdiCheckBold} size="1rem" color="#7abfac" />
								</Box>
								<span>{t("home:freeDelivery")}</span>
							</li>
						</ul>
					</div>
				</Box>
				<img src={welcomeImg} className={classes.hero} alt="" />
			</Container>
		</div>
	)
}

const rhmImg = createCloudinaryLegacyURL("Homepage/yhskzjjrdw3ixgcr727k.png", {
	cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
	secure: true,
	transformation: [
		{ height: 300 },
		{ crop: "fill" },
		{ dpr: "auto" },
		{ fetchFormat: "auto" },
		{ quality: "auto" },
	],
})

const RHMUDM = () => {
	const { classes } = useStyles()

	return (
		<div style={{ background: "white" }}>
			<RhmUdmBg
				style={{
					width: "100%",
					position: "absolute",
					top: 0,
				}}
			/>
			<Container style={{ position: "relative", height: "320px" }}>
				<Box py={5}>
					<div style={{ position: "relative", zIndex: 1, color: "black" }}>
						<div>
							<div style={{ fontWeight: "bold" }}>Neu in unserem Sortiment</div>
							<h1
								className={classes.welcomeBannerH1}
								style={{ marginBottom: 0, fontSize: "2rem" }}
							>
								Secureo RHM & UDM Schließzylinder
							</h1>
						</div>
						<ul className={classes.welcomeBannerUl}>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiFileCertificate} size="1rem" color="#7abfac" />
								</Box>
								<span>Lange Patentlaufzeit bis 2031</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1} style={{ marginTop: "5px" }}>
									<Icon path={mdiShieldLock} size="1rem" color="#7abfac" />
								</Box>
								<span>Bewährtes Stiftsystem für sehr gute Sicherheit</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1} style={{ marginTop: "5px" }}>
									<Icon path={mdiContentCopy} size="1rem" color="#7abfac" />
								</Box>
								<span>3-dimensionales Profil für hohen Kopierschutz</span>
							</li>
						</ul>
					</div>
				</Box>
				<img src={rhmImg} alt="" className={classes.hero} />
			</Container>
		</div>
	)
}

const cctvImg = createCloudinaryLegacyURL("Homepage%20Banner/cctv.png", {
	cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
	secure: true,
	transformation: [
		{ height: 300 },
		{ crop: "fill" },
		{ dpr: "auto" },
		{ fetchFormat: "auto" },
		{ quality: "auto" },
	],
})

const VideoUeberwachung = () => {
	const { classes } = useStyles()

	return (
		<div>
			<VideoueberwachungBg
				style={{
					width: "100%",
					position: "absolute",
					top: 0,
				}}
			/>
			<Container style={{ position: "relative", height: "320px" }}>
				<Box py={5}>
					<div style={{ position: "relative", zIndex: 1 }}>
						<div>
							<div style={{ fontWeight: "bold" }}>
								Behalten Sie stets den Überblick mit
							</div>
							<h1 className={classes.welcomeBannerH1}>
								Videoüberwachung von SafeHero
							</h1>
						</div>
						<ul className={classes.welcomeBannerUl}>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiAlertDecagram} size="1rem" color="#274041" />
								</Box>
								<span>Bedrohungen für Ihr Haus schnell erkennen</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiImageMultiple} size="1rem" color="#274041" />
								</Box>
								<span>Zuverlässige & beweiskräftige Bilder</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiAccountAlert} size="1.3rem" color="#274041" />
								</Box>
								<span>Identifizieren Sie Übeltäter schneller</span>
							</li>
						</ul>
					</div>
				</Box>
				<img src={cctvImg} alt="" className={classes.hero} />
			</Container>
		</div>
	)
}

const getLocalizedPrice = (countryCode: CountryCode) => {
	switch (countryCode) {
		case "AT":
			return "389"
		case "CH":
			return "583,5"
		default:
		case "DE":
			return "389"
	}
}

const airkeyImg = createCloudinaryLegacyURL("Homepage%20Banner/evva-airkey.png", {
	cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
	secure: true,
	transformation: [
		{ height: 300 },
		{ crop: "fill" },
		{ dpr: "auto" },
		{ fetchFormat: "auto" },
		{ quality: "auto" },
	],
})

const EvvaAirkey = () => {
	const { classes } = useStyles()

	const slug = "evva-airkey-starter-paket-digitalzylinder-mit-einseitigem-zutritt"

	const { countryCode } = useContext(AppContext)

	const airKeyPrice = getLocalizedPrice(countryCode)

	return (
		<Link href={`/p/${slug}`}>
			<EvvaAirkeyBg
				style={{
					width: "100%",
					position: "absolute",
					top: 0,
				}}
			/>
			<Container style={{ position: "relative", height: "320px" }}>
				<Box py={5}>
					<div style={{ position: "relative", zIndex: 1 }}>
						<div>
							<div style={{ fontWeight: "bold" }}>Evva AirKey Digitalzylinder</div>
							<h1 className={classes.welcomeBannerH1}>
								Starter Paket mit einseitigem Zutritt
							</h1>
						</div>
						<ul className={classes.welcomeBannerUl}>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiKeyVariant} size="1rem" color="#274041" />
								</Box>
								<span>Via Internet Schlüssel auf Smartphones verschicken</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiKeyChange} size="1rem" color="#274041" />
								</Box>
								<span>Für kleine bis komplexe Schließanlagen</span>
							</li>
							<li className={classes.welcomeBannerLi}>
								<Box component="span" mr={1}>
									<Icon path={mdiCertificate} size="1rem" color="#274041" />
								</Box>
								<span>Produktqualität und Datensicherheit aus Österreich</span>
							</li>
						</ul>
					</div>
					<Hidden mdUp>
						<div>
							ab <span>€ {airKeyPrice},-</span> inkl. MwSt.
						</div>
					</Hidden>
				</Box>

				<Hidden mdDown>
					<div style={{ position: "absolute", right: 0, top: -10, zIndex: 2 }}>
						<div
							style={{
								backgroundImage:
									"url(https://res.cloudinary.com/safehero/image/upload/v1573466460/Homepage%20Banner/ribbon.png)",
								height: "120px",
								width: "160px",
								backgroundRepeat: "no-repeat",
								color: "white",
								textAlign: "center",
								paddingTop: "20px",
							}}
						>
							<div className="small">ab</div>
							<div className="h1" style={{ fontSize: "20px" }}>
								€ {airKeyPrice},-
							</div>
							<div className="small">inkl. MwSt.</div>
						</div>
					</div>
				</Hidden>
				<img
					src={airkeyImg}
					alt="Airkey"
					className={classes.object}
					style={{ maxWidth: "490px" }}
				/>
			</Container>
		</Link>
	)
}

const Sliderv3 = ({ language }: Props) => {
	const settings = {
		dots: false,
		speed: 1000,
		arrows: true,
		infinite: true,
		autoplay: false,
		lazyLoad: true,
		initialSlide: 0,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: true,
		autoplaySpeed: 5000,
		adaptiveHeight: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	}

	return (
		<>
			<Box>
				<Slider {...settings}>
					<div>
						<Welcome language={language} />
					</div>
					{language === "de" && (
						<>
							<div>
								<RHMUDM />
							</div>
							<div>
								<VideoUeberwachung />
							</div>
							<div>
								<EvvaAirkey />
							</div>
						</>
					)}
				</Slider>
			</Box>
		</>
	)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SampleNextArrow = ({ className = "", onClick = () => {} }: ArrowProps) => {
	return (
		<Hidden smDown>
			<div
				className={className}
				style={{
					display: "block",
					background: "rgba(255, 255, 255, 0.5)",
					position: "absolute",
					border: 0,
					outline: 0,
					right: "0",
					zIndex: 1,
					borderRadius: "50px",
					padding: "15px",
					marginRight: "30px",
					cursor: "pointer",
				}}
				onClick={onClick}
			/>
		</Hidden>
	)
}
interface ArrowProps {
	className?: string
	onClick?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SamplePrevArrow = ({ className = "", onClick = () => {} }: ArrowProps) => {
	return (
		<Hidden smDown>
			<div
				className={className}
				style={{
					display: "block",
					background: "rgba(255, 255, 255, 0.5)",
					position: "absolute",
					border: 0,
					outline: 0,
					left: "0",
					zIndex: 1,
					borderRadius: "50px",
					padding: "15px",
					marginLeft: "30px",
					cursor: "pointer",
				}}
				onClick={onClick}
			/>
		</Hidden>
	)
}

interface Props {
	language: string
}

export default Sliderv3
